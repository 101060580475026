<template>
  <label v-bind:for="component_meta.variable" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <div>
    <template v-for="index in component_chain_length" v-bind:key="index">
      <label v-bind:for="component_meta.variable" v-if="component_meta.component.properties.show_chain_label == 'Y'">
        {{ component_chains[index - 1].title }}：
      </label>
      <select class="form-control" v-model="component_value[index - 1]" @change="change_value(index, $event)"
        v-bind:style="{ 'width': component_width + '%', 'display': 'inline', 'margin-right': '3px' }">
        <option v-for="(item, key) in component_selects[index - 1]" v-bind:key="index + key" v-bind:value="key">
          {{ item.title }}
        </option>
      </select>
    </template>
  </div>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    // 鍊的設定
    let chains = this.field.component.properties.chain;

    // 鍊的長度
    let chain_length = chains.length;

    // 每個選單的寬度
    let width = (100 / chain_length) - 1;


    let localValue = (this.value == undefined || this.value == '') ? [] : this.value;
    if (localValue.length == 0) {
      for (let index = 0; index < chain_length; index++) {
        localValue.push('');
      }
    }

    for (let index = 0; index < chain_length; index++) {
      if (localValue[index] == null) {
        localValue[index] = '';
      }
    }

    let component_selects = [];
    let component_select = {};

    for (let index = 0; index < chain_length; index++) {

      if (index == 0) {
        component_select = this.get_chain_select(chains[index]);
      }
      else if (index > 0 && localValue[index - 1] == '') {
        component_select = { '': { 'title': '請選擇' } };
      }
      else {
        component_select = this.get_chain_select(chains[index], localValue[index - 1]);
      }
      component_selects.push(component_select);
    }


    return {
      // 元件識別碼
      component_id: 'select-chain-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: this.field,

      // 元件值
      component_value: localValue,

      // 元件選項
      component_selects: component_selects,

      // 元件訊息
      component_message: this.message,

      // 選單鍊的長度
      component_chain_length: chain_length,

      // 元件的寬度
      component_width: width,

      // 選單鍊的設定
      component_chains: chains

    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;

    }
  },
  methods: {
    change_value: function (index, $event) {
      let id = $event.target.value;
      this.component_value[index - 1] = id;

      // 如有下一個選單，則重新載入下一個選單的選項
      // 鍊的設定
      let chains = this.component_meta.component.properties.chain;

      // 鍊的長度
      let chain_length = chains.length;

      // 如果沒有下一個鍊，則不做任何事
      if (index >= chain_length) {
        return;
      }

      // 取得下一個鍊的設定
      let component_select = this.get_chain_select(chains[index], id);

      this.component_selects[index] = component_select;

      for (let i = index; i < chain_length; i++) {
        this.component_value[i] = '';
      }

      $event.temp = this.component_value;
      this.$emit('input', $event);
    },

    // 根據鍊的設定，取得選單的選項
    get_chain_select: function (chain, selected_id) {

      if (selected_id == undefined || selected_id == null) {
        selected_id = '';
      }

      let component_select = {};

      // 載入組件清單給下拉元件
      if (chain.source_json != '' && chain.source_json != undefined) {
        component_select = JSON.parse(chain.source_json);
        for (let key in component_select) {
          component_select[key] = { 'title': component_select[key] };
        }

      }
      else {

        let parameters = '';
        let params = [];

        if (chain.sort_fields != '' && chain.sort_fields != undefined) {
          let sort_fields = JSON.parse(chain.sort_fields);
          for (let key in sort_fields) {
            params['_s_[' + key + ']'] = sort_fields[key];
          }
        }

        if (chain.datazone != '' && chain.datazone != undefined) {
          params['dz'] = chain.datazone;
        }

        if (chain.param != '' && chain.param != undefined && selected_id != '') {
          params[chain.param] = selected_id;
        }

        // 將 params 物件轉換成網址字串
        // 如果面沒有參數，則不加入 & 字元
        for (let pkey in params) {
          if (parameters != '') {
            parameters += '&';
          }
          parameters += `${pkey}=${params[pkey]}`;
        }

        if (parameters != '') {
          parameters = '?' + parameters;
        }

        let api_url = this.$microlink.base_api_url + chain.source_api + parameters;

        // 如果有設定快取，則從快取中取得資料
        if (chain.pool == 'Y') {

          let pool_key = this.$microlink.base64_encode(api_url);
          if (this.$microlink.get_data_pool(pool_key) == undefined) {
            component_select = this.$microlink.api(api_url);
            component_select = component_select.data;
            this.$microlink.set_data_pool(pool_key, component_select);
          }
          else {
            component_select = this.$microlink.get_data_pool(pool_key);
          }
        }
        else {
          component_select = this.$microlink.api(api_url);
          component_select = component_select.data;
        }

      }

      component_select = { '': { 'title': '請選擇' }, ...component_select };

      return component_select;
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}


div.invalid-mssage {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}

label {
  display: block;
}
</style>